import { render, staticRenderFns } from "./CompetitionListFilter.vue?vue&type=template&id=0ef63a76&scoped=true&"
import script from "./CompetitionListFilter.vue?vue&type=script&lang=js&"
export * from "./CompetitionListFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef63a76",
  null
  
)

export default component.exports