<template>
  <div>
    <b-button
      type="button"
      variant="primary"
      class="mb-2"
      :to="{ name: 'competitions-create' }"
    >
      <span class="text-nowrap">Creer une compétition</span>
    </b-button>
    <b-card no-body>
      <competitions-list-filters
        :status-filter.sync="statusFilter"
        :trashed-filter.sync="trashedFilter"
        :status-options="statusOptions"
        :trashed-options="trashedOptions"
      />
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="fetchCompetitions(true)"
            />
            <label>entrées</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form @submit.prevent="fetchCompetitions(true)">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
                <b-button
                  type="submit"
                  variant="primary"
                >
                  <span class="text-nowrap">Chercher</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="competitions"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun résultat trouvé"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="loading"
        @sort-changed="sortCompetitions($event)"
      >
        <!-- busy -->
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <!-- Column: platform -->
        <template #cell(name)="data">
          <b-media
            vertical-align="center"
            :class="{'cursor-not-allowed':!!data.item.deleted_at}"
          >
            <template #aside>
              <b-avatar
                :disabled="!!data.item.deleted_at"
                :class="{'cursor-not-allowed':!!data.item.deleted_at}"
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                :to="{ name: 'competitions-view', params: { id: data.item.uuid } }"
              />
            </template>
            <b-link
              :disabled="!!data.item.deleted_at"
              :to="{ name: 'competitions-view', params: { id: data.item.uuid } }"
              class="font-weight-bold d-block text-nowrap"
              :style="{cursor: data.item.deleted_at ? 'not-allowed': ''}"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: competition -->
        <template #cell(platforms_count)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="LayoutIcon"
              size="18"
              class="mr-50"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.platforms_count
            }}</span>
          </div>
        </template>

        <!-- Column: competition -->
        <template #cell(is_orderable)="data">
          <div class="text-nowrap">
            <b-badge :variant="(data.item.is_orderable ? 'light-success' : 'light-danger')">
              {{ data.item.is_orderable }}
            </b-badge>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <span>
            <b-badge :variant="resolveStatusVariant(data.item)">
              {{ data.item.status }}

            </b-badge>
          </span>
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          <span>{{ moment(data.value).format("LLL") }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <template v-if="!data.item.deleted_at">
              <feather-icon
                :id="`competition-row-${data.item.uuid}-show-icon`"
                icon="EyeIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({ name: 'competitions-view', params: { id: data.item.uuid }})"
              />
              <b-tooltip
                title="Détails"
                class="cursor-pointer"
                :target="`competition-row-${data.item.uuid}-show-icon`"
              />

              <feather-icon
                :id="`competition-row-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mx-1"
                size="16"
                @click="$router.push({ name: 'competitions-edit', params: { id: data.item.uuid }})"
              />
              <b-tooltip
                title="Éditer"
                class="cursor-pointer"
                :target="`competition-row-${data.item.uuid}-edit-icon`"
              />

              <feather-icon
                :id="`competition-row-${data.item.uuid}-delete-icon`"
                v-b-modal="`modal-delete-competition-${data.item.uuid}`"
                icon="TrashIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Supprimer"
                class="cursor-pointer"
                :target="`competition-row-${data.item.uuid}-delete-icon`"
              />
              <b-modal
                :id="`modal-delete-competition-${data.item.uuid}`"
                :ref="`modal-delete-competition-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Supprimer"
                modal-class="modal-danger"
                centered
                title="Suppression de la compétition"
              >
                <b-card-text>
                  Souhaitez vous vraiment supprimer cette competition ?<br>
                  Ces éléments seront supprimés :<br>
                  -Questions<br>
                  -Réponses<br>
                  -Matchs<br>
                  -Équipes sportives<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Les points des joueurs seront remis à 0 (ainsi que le classement)
                  <p class="text-danger font-weight-bold">
                    (Cette action est réversible)
                  </p>
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-delete-competition-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="danger"
                    :disabled="deleteLoading"
                    @click="deleteCompetition(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="deleteLoading"
                      small
                    />
                    supprimer
                  </b-button>
                </template>
              </b-modal>
            </template>
            <template v-if="data.item.deleted_at">
              <feather-icon
                :id="`competition-row-${data.item.uuid}-restore-icon`"
                v-b-modal="`modal-restore-competition-${data.item.uuid}`"
                icon="RotateCcwIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
              <b-tooltip
                title="Restaurer"
                class="cursor-pointer"
                :target="`competition-row-${data.item.uuid}-restore-icon`"
              />
              <b-modal
                :id="`modal-restore-competition-${data.item.uuid}`"
                :ref="`modal-restore-competition-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Restaurer"
                modal-class="modal-danger"
                centered
                title="Restauration de la compétition"
              >
                <b-card-text>
                  Souhaitez vous vraiment restaurer cette competition ?<br>
                  Ces éléments seront restaurés :<br>
                  -Questions<br>
                  -Réponses<br>
                  -Matchs<br>
                  -Équipes sportives<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Les points des joueurs seront remis à 0 (ainsi que le classement)
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-restore-competition-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="success"
                    :disabled="restoreLoading"
                    @click="restoreCompetition(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="restoreLoading"
                      small
                    />
                    restaurer
                  </b-button>
                </template>
              </b-modal>

              <feather-icon
                :id="`competition-row-${data.item.uuid}-force-delete-icon`"
                v-b-modal="`modal-force-delete-competition-${data.item.uuid}`"
                icon="TrashIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Supprimer définitivement"
                class="cursor-pointer"
                :target="`competition-row-${data.item.uuid}-force-delete-icon`"
              />
              <b-modal
                :id="`modal-force-delete-competition-${data.item.uuid}`"
                :ref="`modal-force-delete-competition-${data.item.uuid}`"
                ok-variant="danger"
                ok-title="Supprimer"
                modal-class="modal-danger"
                centered
                title="Suppression définitive de la compétition"
              >
                <b-card-text>
                  Souhaitez vous vraiment supprimer définitivement cette competition ?<br>
                  Ces éléments seront supprimés :<br>
                  -Questions<br>
                  -Réponses<br>
                  -Matchs<br>
                  -Équipes sportives<br>
                  -Pronostics (sur les matchs et les questions)<br>
                  -Les points des joueurs seront remis à 0 (ainsi que le classement)
                  <p class="text-danger font-weight-bold">
                    (CETTE ACTION EST IRRÉVERSIBLE)
                  </p>
                </b-card-text>
                <template #modal-footer>
                  <b-button
                    variant="secondary"
                    @click="hideModal(`modal-force-delete-competition-${data.item.uuid}`)"
                  >
                    annuler
                  </b-button>
                  <b-button
                    variant="danger"
                    :disabled="forceDeleteLoading"
                    @click="forceDeleteCompetition(data.item.uuid)"
                  >
                    <b-spinner
                      v-show="forceDeleteLoading"
                      small
                    />
                    supprimer
                  </b-button>
                </template>
              </b-modal>
            </template>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.total }} entrées</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCompetitions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard, BCardText,
  BCol,
  BForm,
  BFormInput,
  BLink,
  BMedia, BModal, BPagination,
  BRow, BSpinner,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CompetitionsListFilters from './CompetitionListFilter.vue'

export default {
  name: 'CompetitionsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    CompetitionsListFilters,
    BTooltip,
    BModal,
    BCardText,
  },
  setup() {
    const resolveStatusVariant = competition => {
      if (competition.status === 'PENDING') return 'light-info'
      if (competition.status === 'RUNNING') return 'light-success'
      if (competition.status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    return {
      avatarText,
      resolveStatusVariant,
    }
  },
  data() {
    return {
      loading: true,
      trashedFilter: false,
      statusFilter: null,
      forceDeleteLoading: false,
      deleteLoading: false,
      restoreLoading: false,
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
      trashedOptions: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      tableColumns: [
        {
          key: 'name',
          label: 'Nom',
          sortable: true,
        },
        {
          key: 'sport',
          label: 'Sport',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'platforms_count',
          label: 'Nombre de plateformes',
          sortable: false,
        },
        {
          key: 'is_orderable',
          label: 'Commandable',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        { key: 'actions' },
      ],
      sortBy: 'name',
      isSortDirDesc: false,
      competitions: [],
      currentPage: 1,
      totalCompetitions: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  watch: {
    trashedFilter: {
      handler() {
        this.fetchCompetitions(true)
      },
    },
    statusFilter: {
      handler() {
        this.fetchCompetitions(true)
      },
    },
  },
  mounted() {
    this.fetchCompetitions()
  },
  methods: {
    async fetchCompetitions(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get('/competitions', {
          params: {
            onlyTrashed: this.trashedFilter,
            status: this.statusFilter,
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
          },
        })
        this.competitions = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalCompetitions = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async forceDeleteCompetition(uuid) {
      try {
        this.forceDeleteLoading = true
        await axiosIns.delete(`competitions/${uuid}/forceDelete`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La competition a été définitivement supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.forceDeleteLoading = false
        this.hideModal(`modal-force-delete-competition-${uuid}`)
        this.fetchCompetitions()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.forceDeleteLoading = false
      }
    },
    async deleteCompetition(uuid) {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`competitions/${uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La competition a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal(`modal-delete-competition-${uuid}`)
        this.fetchCompetitions()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    async restoreCompetition(uuid) {
      try {
        this.restoreLoading = true
        await axiosIns.post(`competitions/${uuid}/restore`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La competition a été restaurée',
            },
          },
          { timeout: 5000 },
        )
        this.restoreLoading = false
        this.hideModal(`modal-restore-competition-${uuid}`)
        this.fetchCompetitions()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.restoreLoading = false
      }
    },
    sortCompetitions(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchCompetitions()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchCompetitions()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
